.custom-modal {
  position: fixed;
  z-index: 1041;
  top: 20%;
  width: 80%;
  background: #000;
  left: 50%;
  transform: translateX(-50%);

  @include media-breakpoint-up(md) {
    width: 40%;
  }

  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border-bottom: 1px solid #ccc;

    h4 {
      margin: 0;
    }
  }

  .body {
    padding: 1rem;
  }

  .footer {
    display: flex;
    flex-direction: row-reverse;
    padding: 1rem;
    border-top: 1px solid #ccc;
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}
