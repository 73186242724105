.form-control {
  border-radius: 0;
  background: #ccc;
  border: none;
  border-radius: 0;
  box-shadow: none;
  outline: none;
  color: #000;
  border-bottom: 1px solid #202020;

  &:active,
  &:focus {
    border-bottom: 1px solid #434a52;
    background: #ccc;
    outline: none;
    opacity: 1;
    text-decoration: none;
    box-shadow: unset;
    color: #000;
  }
}

.btn {
  border-radius: 0;
}
