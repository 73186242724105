.bosses {
  background: #202020;
  padding: 1rem;

  .header {
    border-bottom: 1px solid #ccc;
    margin-bottom: 0.5rem;
    display: none;

    @include media-breakpoint-up(md) {
      display: block;
    }
  }

  .boss {
    padding: 0.5rem;

    &:hover {
      background: #4c4c4c;
    }

    span {
      &.green {
        color: #33ff33;
      }

      &.red {
        color: #ff3333;
      }
    }

    .name {
      a {
        display: block;
        text-decoration: none;

        &.map-link {
          color: #fff;
          font-size: 0.75rem;
        }
      }

      span {
        display: block;
        font-size: 0.75rem;
      }
    }

    .actions {
      text-align: left;

      @include media-breakpoint-up(md) {
        text-align: center;
      }

      i {
        margin: 0 0.25rem;
      }
    }
  }

  span {
    &.text-center {
      display: block;
    }
  }
}
